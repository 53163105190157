import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Img from 'react-image';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';

import Container from '~components/Container';
import { HeroTitle, Text } from '~components/Typography';

import DonateHeroImg from '~images/donate/donate-hero-img.png';
import DonateHeroBackgroundImg from '~images/donate/donate-hero-background.jpg';
import PlaylistCardPlaceHolderImage from '~images/placeholders/hero_image_placeholder.png';

const useStyles = makeStyles(theme => ({
  media: {
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  producersCircleButton: {
    backgroundColor: '#f38844',
    height: '45px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#e57833'
    }
  },
  arrowIconStyle: {
    color: '#fff',
    fontSize: '1.5rem',
    marginLeft: theme.spacing(1)
  }
}));

const DonateHero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container size={50} center background={`url(${DonateHeroBackgroundImg})`}>
      <Box py={isSmallerThanMd ? 2 : 1}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={12} md={5}>
            <Img
              src={DonateHeroImg}
              alt="Donate"
              className={classes.media}
              loader={
                <img
                  src={PlaylistCardPlaceHolderImage}
                  alt="placeholder"
                  className={classes.media}
                />
              }
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <HeroTitle className="">Support Meaningful, Exciting, Life-Changing Torah</HeroTitle>
            <Text className="">
              If you are passionate about what we’re doing here at Aleph Beta — teaching Torah that
              activates your brain, touches your heart, and actually impacts the way that you live
              your life — then we invite you to partner with us. Help us to continue to mine the
              mysteries of the Torah, and to reach hundreds of thousands of users, every day. Your
              support makes a world of a difference.
            </Text>

            <Box py={2}>
              <Button
                component={GatsbyLink}
                to="/patrons-circle"
                variant="contained"
                size="large"
                className={classes.producersCircleButton}
                fullWidth
              >
                Want to do more? <ArrowRightIcon className={classes.arrowIconStyle} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

DonateHero.propTypes = {};

export default DonateHero;
