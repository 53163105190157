import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import DonateFormImg from '~images/donate/donate-form-image.jpg';
import { Text } from '~components/Typography';
import Container from '~components/Container';
import MessageBox from '~components/forms/MessageBox';
import { TextAreaInput, TextInput, EmailInput } from '~components/form-inputs';
import products, { calculateDonationAmountWithFees } from '~utils/products';

import { formatPrice } from '~utils/subscribe-helpers';
import { FormControl, Radio, RadioGroup } from '@material-ui/core';
import DonateAmountForm from './DonateAmountForm/DonateAmountForm';
import DonateRecurringDonationButton from './DonateRecurringDonationButton';
import DonateInformationSection from './DonateInformationSection';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '20px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '44px'
    }
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '64px',
    textAlign: 'center',
    color: '#000000',
    [theme.breakpoints.down('xs')]: {
      fontSize: '36px'
    }
  },
  media: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto'
  },
  selectedButton: {
    backgroundColor: '#56bfb8',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#3d9f98'
    }
  },
  selected: {
    color: '#fff',
    fontSize: '0.9375rem',
    borderRadius: '6px',
    backgroundColor: '#56bfb8 !important'
  },
  indicator: {
    display: 'none'
  },
  tabStyle: {
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    backgroundColor: '#e0e0e0',
    fontSize: '0.9375rem',
    marginBottom: '5px',
    borderRadius: '6px',
    opacity: 1,
    '&:not(:first-child)': {
      marginLeft: '10px',
      [theme.breakpoints.only('xs')]: {
        marginLeft: '0px',
        marginTop: '15px'
      }
    }
  },
  tabsStyle: {
    marginBottom: '25px',
    marginTop: '25px'
  },
  flexContainer: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  },
  checkboxBold: {
    fontWeight: 'bold'
  },
  mb0: {
    marginBottom: 0,
    lineHeight: '130%'
  },
  emailNotes: {
    fontSize: 12,
    lineHeight: 1.2,
    color: '#7a7a7a'
  }
}));

const TabPanel = ({ ...props }) => {
  const { children, value, index, ...rest } = props;

  if (value !== index) {
    return null;
  }

  return (
    <div role="tabpanel" id={`nav-tabpanel-${index}`} {...rest}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const DEDICATION_TYPES = [
  'In Honor of',
  'In Memory of',
  'For the safety of',
  'For the safe return of'
];

const DonateForm = ({ donationAmounts, title }) => {
  const classes = useStyles();
  const [donationAmount, setDonationAmount] = useState(50);
  const [recurringDonationId, setRecurringDonationId] = useState(43);
  const [dedicationStatus, setDedicationStatus] = useState(false);
  const [wartimeDedication] = useState(false);
  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [donatorNote, setDonatorNote] = useState('');
  const [dedicationType, setDedicationType] = useState(DEDICATION_TYPES[0]);
  const [error, setError] = useState('');
  const [value, setValue] = React.useState('one-time');
  // Add 'yearly' to the options array for the yearly tab to appear
  const options = ['one-time', 'monthly'];
  const [includeFees, setIncludeFees] = useState(false);
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const inputRef = useRef();

  const changeDonationFeesInclusion = () => {
    setIncludeFees(prevState => !prevState);
  };
  const donationPlans = products.filter(
    product => product.id > 41 && product.id < 70 && product.monthlyPrice > 0
  );

  const handleDonationAmount = (amount, isDefinedAmount) => {
    setDonationAmount(amount);
    if (isDefinedAmount) {
      setIsCustomAmount(false);
    }
  };

  const handleRecurringDonationAmount = (amount, id) => {
    setDonationAmount(amount);
    setRecurringDonationId(id);
  };

  const handleChange = (event, newTabValue) => {
    setValue(newTabValue);

    if (newTabValue === 'one-time') {
      setDonationAmount(50);
      setIsCustomAmount(false);
    }
    if (newTabValue === 'monthly') {
      setDonationAmount(18);
      setRecurringDonationId(43);
    }
    // Yearly Default Value

    // if (newTabValue === 'yearly') {
    //   setDonationAmount('180');
    //   setRecurringDonationId(43);
    // }
  };

  const dedicationValidation = isValid => {
    if (!isValid) {
      setError('If you want to send a dedication message, the dedication note is required.');
    }
  };

  const changeDedicationStatus = () => {
    setDedicationStatus(prevState => !prevState);
  };

  const onCustomAmountClick = () => {
    setIsCustomAmount(true);
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  const handleDedicationTypeChange = event => {
    setDedicationType(event?.target?.value);
  };

  let finalAmount = donationAmount;
  if (value === 'monthly') {
    const recurringDonation = donationPlans.find(product => product.id === recurringDonationId);
    if (!recurringDonation) {
      finalAmount = 'ERROR: NO PLAN FOUND';
    } else {
      finalAmount = includeFees
        ? recurringDonation.monthlyPriceWithFees
        : recurringDonation.monthlyPrice;
    }
  } else if (includeFees) {
    finalAmount = calculateDonationAmountWithFees(donationAmount);
  }

  return (
    <Container center size={66} className={classes.root}>
      <h2 className={classes.title}>{title}</h2>

      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12} md={5}>
          <Typography variant="h5">Donation frequency:</Typography>
          <Box width="100%">
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabsStyle}
              variant="fullWidth"
              classes={{ indicator: classes.indicator, flexContainer: classes.flexContainer }}
            >
              {options &&
                options.map(option => (
                  <Tab
                    label={option}
                    value={option}
                    className={classes.tabStyle}
                    key={option}
                    disableRipple
                    classes={{ selected: classes.selected }}
                  />
                ))}
            </Tabs>
          </Box>

          <Typography variant="h5">I would like to donate:</Typography>

          <TabPanel value={value} index="one-time">
            <Box py={2}>
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
              >
                {donationAmounts &&
                  donationAmounts.map(amount => (
                    <Grid key={amount} item xs={4}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => handleDonationAmount(amount, true)}
                        className={
                          donationAmount === amount && !isCustomAmount ? classes.selectedButton : ''
                        }
                        fullWidth
                      >
                        ${amount}
                      </Button>
                    </Grid>
                  ))}

                <Grid item xs={4}>
                  <Button
                    className={isCustomAmount ? classes.selectedButton : ''}
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={onCustomAmountClick}
                  >
                    Custom
                  </Button>

                  <Typography variant="subtitle1" align="center" color="primary">
                    (Name Your Amount)
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid
              item
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}
              wrap="nowrap"
            >
              <Checkbox color="primary" onChange={changeDedicationStatus} />
              <Text className={`textPrimary ${classes.mb0}`}>
                Dedicate my donation{' '}
                <span className={classes.checkboxBold}>in honor or in memory</span> of someone
              </Text>
            </Grid>
            {error && <MessageBox text={error} isError />}
            {dedicationStatus && (
              <Grid
                item
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                spacing={1}
              >
                <TextInput
                  name="recipientName"
                  label="Recipient Name"
                  value={recipientName}
                  onChange={e => setRecipientName(e.target.value)}
                />
                <EmailInput
                  name="recipientEmail"
                  label="Recipient Email"
                  value={recipientEmail}
                  onChange={e => setRecipientEmail(e.target.value)}
                  required={false}
                />
                <Text className={classes.emailNotes}>
                  Please provide an email address if you would like to notify a recipient of your
                  generous donation. The amount of your gift will remain confidential.
                </Text>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue={DEDICATION_TYPES[0]}
                    onChange={handleDedicationTypeChange}
                  >
                    {DEDICATION_TYPES.map(dtype => (
                      <FormControlLabel
                        key={dtype}
                        value={dtype}
                        control={<Radio color="primary" />}
                        label={dtype}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <TextAreaInput
                  name="donatorNote"
                  label="Add a dedication or custom note *"
                  value={donatorNote}
                  onChange={e => setDonatorNote(e.target.value)}
                />
              </Grid>
            )}
            <DonateAmountForm
              donationAmount={donationAmount}
              handleDonationAmount={setDonationAmount}
              donationNotes={
                dedicationStatus
                  ? { recipientName, recipientEmail, donatorNote, dedicationType }
                  : undefined
              }
              isWartimeDedication={wartimeDedication}
              formValidation={dedicationValidation}
              includeFees={includeFees}
              changeDonationFeesInclusion={changeDonationFeesInclusion}
              calculateDonationAmountWithFees={calculateDonationAmountWithFees}
              inputRef={inputRef}
            />
          </TabPanel>

          <TabPanel value={value} index="monthly">
            <Box py={2}>
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
              >
                {!!donationPlans &&
                  donationPlans.map(monthlyDonationPlan => (
                    <Grid key={monthlyDonationPlan.id} item xs={4}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() =>
                          handleRecurringDonationAmount(
                            monthlyDonationPlan.monthlyPrice,
                            monthlyDonationPlan.id
                          )
                        }
                        className={
                          recurringDonationId === monthlyDonationPlan.id
                            ? classes.selectedButton
                            : ''
                        }
                        fullWidth
                      >
                        ${monthlyDonationPlan.monthlyPrice}
                      </Button>
                    </Grid>
                  ))}
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    size="large"
                    component={GatsbyLink}
                    to="/patrons-circle"
                    fullWidth
                  >
                    $180
                  </Button>

                  <Typography variant="subtitle1" align="center" color="primary">
                    (Perks Included!)
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid
              item
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={includeFees}
                    onChange={changeDonationFeesInclusion}
                  />
                }
                label="Cover the processing fees so 100% of my donation goes to Aleph Beta"
              />
            </Grid>
            <DonateRecurringDonationButton
              recurringDonationId={recurringDonationId}
              recurringDonationFrequency={value}
              isPayingFees={includeFees}
            />
          </TabPanel>

          <DonateInformationSection />

          {/* Yearly recurring donation implementation */}

          {/* <TabPanel value={value} index="yearly">
            <Box py={2}>
              <Grids
                item
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
              >
                {donationPlans &&
                  donationPlans.map(yearlyDonationPlan => (
                    <Grid key={yearlyDonationPlan.id} item xs={4}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() =>
                          handleRecurringDonationAmount(
                            yearlyDonationPlan.yearlyPrice,
                            yearlyDonationPlan.id
                          )
                        }
                        className={
                          donationAmount === `${yearlyDonationPlan.yearlyPrice}`
                            ? classes.selectedButton
                            : ''
                        }
                        fullWidth
                      >
                        ${yearlyDonationPlan.yearlyPrice}
                      </Button>
                    </Grid>
                  ))}
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    size="large"
                    component={GatsbyLink}
                    to="/producers-circle"
                    fullWidth
                  >
                    $1800
                  </Button>

                  <Typography variant="subtitle1" align="center" color="primary">
                    (Perks Included!)
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <DonateRecurringDonationButton
              recurringDonationId={recurringDonationId}
              recurringDonationFrequency={value}
            />
          </TabPanel> */}
        </Grid>

        <Grid item xs={12} md={5}>
          <img src={DonateFormImg} alt="" className={classes.media} />

          <Typography variant="h3" color="primary" align="center">
            ${formatPrice(Number(finalAmount))}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

DonateForm.propTypes = {
  title: PropTypes.string,
  donationAmounts: PropTypes.arrayOf(PropTypes.number).isRequired
};

DonateForm.defaultProps = {
  title: ''
};

export default DonateForm;
