import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import { Typography } from '@material-ui/core';
import VideoPlayCardWithFeaturedText from '~components/VideoPlayCardWithFeaturedText';
import VideoCoverImage from '~images/donate/donate-israel-video-cover.jpg';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '80px',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '40px',
      paddingBottom: '20px'
    }
  },
  spotlightsArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: '75px',
    [theme.breakpoints.down('xs')]: {
      gap: '32px'
    }
  },
  media: {
    aspectRatio: '16 / 9',
    borderRadius: 15,
    minHeight: '100px'
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '64px',
    textAlign: 'center',
    color: '#000000',
    lineHeight: '130%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '36px',
      lineHeight: '120%'
    }
  },
  shapeDivider: {
    display: 'flex'
  },
  spotlight: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      gap: '16px'
    }
  }
}));

const FEATURED_VIDEO = {
  title: 'Happening Now',
  description:
    'Make a donation to support Aleph Beta’s Israel campaign. All campaign funds collected will go to support Midreshet Ziv’s distribution center for aid to soldiers and displaced families.',
  url: 'https://clevertech.wistia.com/medias/g756hbsdsi',
  cover_image: {
    url: VideoCoverImage,
    alt: 'Donate to Israel'
  }
};

const DonateFeaturedVideo = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <div className={classes.spotlightsArea}>
        <div className={classes.spotlight}>
          <Typography component="h2" className={classes.title}>
            Join us in helping raise money for Israel
          </Typography>
          <VideoPlayCardWithFeaturedText
            title={FEATURED_VIDEO.title}
            url={FEATURED_VIDEO.url}
            description={FEATURED_VIDEO.description}
            image={FEATURED_VIDEO?.cover_image}
          />
        </div>
      </div>
    </Container>
  );
};

DonateFeaturedVideo.propTypes = {};

export default DonateFeaturedVideo;
