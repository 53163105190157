/* eslint-disable import/prefer-default-export */
export const formatPrice = price => {
  if (typeof price !== 'number') {
    return '0.0';
  }
  const formattedPrice = Number(price);
  const p = formattedPrice.toFixed(2);
  const r = p.toString().split('.');
  if (r[1] === '00') {
    return r[0];
  }
  return p;
};
