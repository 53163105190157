import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BlackPlayButton from '~images/buttons/play_btn_black.svg';
import WhitePlayButton from '~images/buttons/play_btn_white.svg';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import MediaPlayer from '~components/media-player';
import DesktopDescription from './DesktopDescription';
import MobileDescription from './MobileDescription';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
    position: 'relative'
  },
  media: {
    aspectRatio: '16 / 9',
    borderRadius: 13,
    minHeight: '100px',
    border: '1px solid #FFFFFF',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      borderRadius: '12px 12px 0px 0px'
    }
  },
  playBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 20,
    [theme.breakpoints.down('sm')]: {
      width: 80,
      height: 80
    },
    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 50
    }
  },
  featuredVideo: {
    width: '100%',
    margin: '0 auto'
  }
}));

const config = {
  wistia: {
    options: {
      seo: false,
      videoFoam: true,
      playlistLinks: 'auto',
      playerColor: '#3688FF',
      resumable: false,
      playButton: false
    }
  }
};

const VideoPlayCard = ({ url, title, description, image, author, videoInfo, duration }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isHover, setIsHover] = useState(false);
  const [isPlayClicked, setIsPlayClicked] = useState(false);

  const onCardHover = () => setIsHover(true);
  const onCardHoverOut = () => setIsHover(false);

  const playButtonImage = isSmallerScreen ? WhitePlayButton : BlackPlayButton;

  const handlePlayButtonClick = () => {
    setIsPlayClicked(true);
  };

  if (isPlayClicked) {
    return (
      <div className={classes.featuredVideo}>
        <MediaPlayer url={url} playing config={config} />
      </div>
    );
  }

  return (
    <div
      onClick={handlePlayButtonClick}
      tabIndex={0}
      role="button"
      onKeyDown={event => {
        if (['keydown', 'keypress'].includes(event.type) && ['Enter', ' '].includes(event.key)) {
          handlePlayButtonClick();
        }
      }}
    >
      <div className={classes.root} onMouseEnter={onCardHover} onMouseLeave={onCardHoverOut}>
        {!isSmallerScreen && (
          <DesktopDescription
            title={title}
            description={description}
            author={author}
            videoInfo={videoInfo}
            duration={duration}
          />
        )}
        {(isHover || isSmallerScreen) && (
          <img className={classes.playBtn} src={playButtonImage} alt="play button" />
        )}
        <img className={classes.media} src={image?.url} alt={image?.alt || title} />
      </div>
      {isSmallerScreen && (
        <MobileDescription
          title={title}
          description={description}
          author={author}
          videoInfo={videoInfo}
          duration={duration}
        />
      )}
    </div>
  );
};

VideoPlayCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired,
  author: PropTypes.string,
  videoInfo: PropTypes.string,
  duration: PropTypes.string
};

VideoPlayCard.defaultProps = {
  description: '',
  author: '',
  videoInfo: '',
  duration: ''
};

export default VideoPlayCard;
