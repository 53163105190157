import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '~components/Container';

import { Title, Text } from '~components/Typography';

const DonateAbout = ({ sections }) => (
  <Container center>
    <Box py={5}>
      <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
        {sections &&
          sections.map(section => (
            <Grid key={section.title} item xs={12} md={4}>
              <Title className="">{section.title}</Title>
              <Text className="">{section.text}</Text>
            </Grid>
          ))}
      </Grid>
    </Box>
  </Container>
);

DonateAbout.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired
};

export default DonateAbout;
