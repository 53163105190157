import { isNumber } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import useCheckout from '~hooks/useCheckout';

import TextInput from '~components/form-inputs/TextInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';

import products, { PRODUCT_IDS, calculateDonationAmountWithFees } from '~utils/products';

const DonateAmountForm = ({
  donationAmount,
  handleDonationAmount,
  isWartimeDedication,
  donationNotes,
  formValidation,
  includeFees,
  changeDonationFeesInclusion,
  inputRef
}) => {
  const [, setCheckoutState] = useCheckout();
  const [isError, setIsError] = useState(false);

  const handleAmount = event => {
    handleDonationAmount(event.target.value);
  };

  const proceedToCheckout = () => {
    const amountAsNumber = parseInt(donationAmount, 10);
    const isValidAmount = isNumber(amountAsNumber) && amountAsNumber > 0;

    if (isValidAmount) {
      const finalAmount = includeFees
        ? calculateDonationAmountWithFees(amountAsNumber)
        : amountAsNumber;
      const product = products.find(({ id }) => id === PRODUCT_IDS.Donation);
      setCheckoutState({
        product: { ...product, monthlyPrice: finalAmount, yearlyPrice: finalAmount },
        isWartimeDedication,
        donationNotes
      });
      navigate('/checkout');
    } else {
      setIsError(true);
    }
  };

  const onSubmit = () => {
    if (donationNotes && donationNotes.donatorNote) {
      proceedToCheckout();
    } else if (!donationNotes) {
      proceedToCheckout();
    } else {
      formValidation(false);
    }
  };

  return (
    <>
      <TextInput
        label="Name Your Amount"
        type="number"
        name="amount"
        value={donationAmount}
        error={isError}
        onChange={handleAmount}
        helperText={isError ? 'Invalid amount' : ''}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        inputRef={inputRef}
      />
      <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={1}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={includeFees}
              onChange={changeDonationFeesInclusion}
            />
          }
          label="Cover the processing fees so 100% of my donation goes directly to the cause"
        />
      </Grid>
      <ButtonSubmit type="button" onClick={onSubmit} size="large">
        Donate Now
      </ButtonSubmit>
    </>
  );
};

DonateAmountForm.propTypes = {
  donationAmount: PropTypes.string,
  handleDonationAmount: PropTypes.func.isRequired,
  isWartimeDedication: PropTypes.bool,
  formValidation: PropTypes.func.isRequired,
  donationNotes: PropTypes.shape({
    recipientName: PropTypes.string,
    recipientEmail: PropTypes.string,
    donatorNote: PropTypes.string
  }),
  includeFees: PropTypes.bool,
  changeDonationFeesInclusion: PropTypes.func.isRequired,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.node })])
};

DonateAmountForm.defaultProps = {
  donationAmount: '',
  donationNotes: undefined,
  includeFees: false,
  isWartimeDedication: false,
  inputRef: () => {}
};

export default DonateAmountForm;
