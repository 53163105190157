import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import EmailIcon from '@material-ui/icons/EmailOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';

import Container from '~components/Container';
import Link from '~components/Link';
import DonateContactImg from '~images/donate/donate-contact-image.png';

const useStyles = makeStyles(theme => ({
  media: {
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  iconStyle: {
    color: '#56bfb8',
    fontSize: '85px'
  },
  TextAlign: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  dialogStyle: {
    background: 'transparent',
    boxShadow: 'unset',
    margin: '20px'
  }
}));

const DonateContact = ({ background, color }) => {
  const classes = useStyles();

  return (
    <Container size={66} center background={background}>
      <Box id="contact" color={color} width="100%" py={2}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} md={6} className={classes.TextAlign}>
            <Typography variant="h4" gutterBottom>
              To donate by mail or contact our team:
            </Typography>

            <Grid item container direction="row" justify="center" alignItems="center">
              <Grid item xs={12} md={3}>
                <EmailIcon className={classes.iconStyle} />
              </Grid>

              <Grid item xs={12} md={9}>
                <Typography variant="h6" gutterBottom>
                  Send check or money order to:
                </Typography>
                <Typography variant="subtitle1">
                  Hoffberger Institute for Text Study, Inc
                </Typography>
                <Typography variant="subtitle1">3 E Evergreen Rd Unit 101 #708</Typography>
                <Typography variant="subtitle1" gutterBottom>
                  New City, NY 10956
                </Typography>
              </Grid>
            </Grid>

            <Grid item container direction="row" justify="center" alignItems="center">
              <Grid item xs={12} md={3}>
                <PhoneIcon className={classes.iconStyle} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="h6" gutterBottom>
                  Call or email our Support Team at:
                </Typography>
                <Link to="mailto:info@alephbeta.org?Subject=Donation" variant="subtitle1">
                  info@alephbeta.org
                </Link>
                <br />
                <Link to="tel:(516)253-5691" variant="subtitle1">
                  (516) 253-5691
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <img
              src={DonateContactImg}
              alt="Seal of transparency"
              className={`${classes.media} lazyload`}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

DonateContact.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string
};

DonateContact.defaultProps = {
  background: null,
  color: null
};

export default DonateContact;
