import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Typography } from '@material-ui/core';
import WhitePlayButton from '~images/buttons/play_btn_white.svg';

const useStyles = makeStyles(() => ({
  description: {
    position: 'absolute',
    width: '100%',
    bottom: 44,
    left: 0,
    zIndex: 20,
    paddingLeft: 44,
    paddingRight: 44,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 4
  },
  titleText: {
    fontWeight: 500,
    fontSize: '48px',
    color: '#FFFFFF',
    maxWidth: '485px'
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '150%',
    color: '#FFFFFF',
    maxWidth: '396px'
  },
  overlayVertical: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, transparent, transparent 30%, rgba(0,0,0, 1))',
    borderRadius: 13,
    border: '1px solid #FFFFFF'
  },
  overlayHorizontal: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to left, transparent, transparent 30%, rgba(0,0,0, 1))',
    borderRadius: 13,
    border: '1px solid #FFFFFF'
  },
  videoInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 20
  },
  infoText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    color: '#C9C9C9'
  },
  playBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 20
  }
}));

const DesktopDescription = ({ title, description, author, videoInfo, duration }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.description}>
        <Box display="flex" flexDirection="column" gridRowGap="4px">
          <Typography component="h3" className={classes.titleText}>
            {title}
          </Typography>
          <Typography component="p" className={classes.descriptionText}>
            {description}
          </Typography>
        </Box>
        {(author || videoInfo || duration) && (
          <div className={classes.videoInfo}>
            <Typography component="p" className={classes.infoText}>
              {`${author} - ${videoInfo} - ${duration}`}
            </Typography>
          </div>
        )}
      </div>
      <img className={classes.playBtn} src={WhitePlayButton} alt="play button active" />
      <div className={classes.overlayHorizontal} />
    </>
  );
};

DesktopDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  author: PropTypes.string,
  videoInfo: PropTypes.string,
  duration: PropTypes.string
};

DesktopDescription.defaultProps = {
  description: '',
  author: '',
  videoInfo: '',
  duration: ''
};

export default DesktopDescription;
