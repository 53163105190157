import React from 'react';
import Img from 'react-image';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Container from '~components/Container';
import { HeroTitle, Text } from '~components/Typography';
import sponsorAdayImg from '~images/donate/sponsor-a-day.png';
import PlaylistCardPlaceHolderImage from '~images/placeholders/hero_image_placeholder.png';

const useStyles = makeStyles(theme => ({
  media: {
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.only('sm')]: {
      width: '100vw'
    }
  },
  producersCircleButton: {
    backgroundColor: '#f38844',
    height: '45px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#e57833'
    }
  },
  arrowIconStyle: {
    color: '#fff',
    fontSize: '1.5rem',
    marginLeft: theme.spacing(1)
  },
  containerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '90px',
      paddingBottom: '90px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '44px',
      paddingBottom: '44px'
    }
  },
  sponsorText: {
    [theme.breakpoints.only('md')]: {
      alignSelf: 'flex-start'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '48px'
    },
    color: '#403D44',
    fontFamily: 'Inter',
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%',
    '& > *': { color: '#58A8F7' }
  },
  descriptionText: {
    color: '#403C44',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '158%',
    marginTop: '32px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  emailStyle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    },
    color: '#403C44',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '158%',
    textDecorationLine: 'underline'
  }
}));

const SponsorAday = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isBiggerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      className={classes.containerStyle}
      size={isBiggerThanMd && 30}
      center
      background="#F6F6F6"
    >
      {isMd && (
        <HeroTitle className={classes.sponsorText}>
          Sponsor <span>a day of learning</span>{' '}
        </HeroTitle>
      )}
      <Box>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={6}>
            {!isMd && (
              <HeroTitle className={classes.sponsorText}>
                Sponsor <span>a day of learning</span>
              </HeroTitle>
            )}
            <Text className={classes.descriptionText}>
              Looking for a meaningful way to honor or celebrate a loved one? We are now offering
              the ability to sponsor a day, a week, or a month of learning across our site. We have
              20,000 active users visiting our site weekly across the globe. Your dedication will
              not only be seen by them, but will also be emailed out in our newsletter to over
              75,000 active recipients.
            </Text>
            <Text className={classes.descriptionText}>
              To reserve your dates (whether on the Hebrew or English calendar), and set up your
              sponsorship{' '}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSc5-eDayykgt7cpuFCLmPaABRmB289nNpOherlPlkLIAfFfxg/viewform?usp=sf_link"
                className={classes.emailStyle}
                target="_blank"
                rel="noreferrer"
              >
                click here
              </a>
              . For more information, please email{' '}
              <a href="mailto: info@alephbeta.org" className={classes.emailStyle}>
                info@alephbeta.org
              </a>
              .
            </Text>
          </Grid>
          <Grid item xs={12} md={5}>
            <Img
              src={sponsorAdayImg}
              alt="Sponsor a day"
              className={classes.media}
              loader={
                <img
                  src={PlaylistCardPlaceHolderImage}
                  alt="placeholder"
                  className={classes.media}
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default SponsorAday;
