import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  description: {
    width: '100%',
    padding: '14px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 8,
    backgroundColor: 'rgb(0,0,0)',
    borderRadius: '0px 0px 12px 12px'
  },
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '18px',
    color: 'rgba(255, 255, 255, 0.85)',
    textAlign: 'center'
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    color: 'rgba(255, 255, 255, 0.7)',
    textAlign: 'center'
  },
  videoInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 20
  },
  infoText: {
    fontFamily: 'Inter',
    fontSize: '12px',
    color: 'rgba(255, 255, 255, 0.4)',
    textAlign: 'center'
  }
}));

const MobileDescription = ({ title, description, author, videoInfo, duration }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.description}>
        <Box display="flex" flexDirection="column" gridRowGap="4px">
          <Typography component="h3" className={classes.titleText}>
            {title}
          </Typography>
          <Typography component="p" className={classes.descriptionText}>
            {description}
          </Typography>
        </Box>
        {(author || videoInfo || duration) && (
          <div className={classes.videoInfo}>
            <Typography component="p" className={classes.infoText}>
              {`${author} - ${videoInfo} - ${duration}`}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

MobileDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  author: PropTypes.string,
  videoInfo: PropTypes.string,
  duration: PropTypes.string
};

MobileDescription.defaultProps = {
  description: '',
  author: '',
  videoInfo: '',
  duration: ''
};

export default MobileDescription;
