import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import useCheckout from '~hooks/useCheckout';

import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import MessageBox from '~components/forms/MessageBox';
import products from '~utils/products';

const DonateRecurringDonationButton = ({
  recurringDonationId,
  recurringDonationFrequency,
  isPayingFees
}) => {
  const [, setCheckoutState] = useCheckout();
  const [isError, setIsError] = useState(false);
  const formatedBillingCycle = recurringDonationFrequency.slice(0, -2);

  const onSubmit = () => {
    if (!recurringDonationId) {
      setIsError(true);
      return;
    }
    const product = products.find(({ id }) => id === recurringDonationId);

    setCheckoutState({
      product,
      billingCycle: formatedBillingCycle,
      isPayingFees
    });
    navigate('/checkout');
  };

  return (
    <>
      {isError && (
        <MessageBox
          isError
          text={`Please select one of the ${recurringDonationFrequency} donation plans.`}
        />
      )}
      <ButtonSubmit type="button" onClick={onSubmit} size="large">
        Donate Now
      </ButtonSubmit>
    </>
  );
};

DonateRecurringDonationButton.propTypes = {
  recurringDonationId: PropTypes.number,
  recurringDonationFrequency: PropTypes.string,
  isPayingFees: PropTypes.bool
};

DonateRecurringDonationButton.defaultProps = {
  recurringDonationId: null,
  recurringDonationFrequency: null,
  isPayingFees: false
};

export default DonateRecurringDonationButton;
